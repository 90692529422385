import * as React from "react"

const IndexPage = () => {
  return (
    <>
      <div className="video23 frontpage" style={{"--aspect-ratio":16/9}}>
        <iframe 
            title="intro" 
            src="https://academy.23video.com/68470923.ihtml/player.html?token=2d4f67f3e3aaaa79c4177d19fce572f1&source=embed&photo%5fid=68470923&autoPlay=0&showDescriptions=0&showLogo=0&socialSharing=0&defaultQuality=fullhd"
            frameBorder="0" 
            border="0" 
            scrolling="no" 
            mozallowfullscreen="0" 
            webkitallowfullscreen="0" 
            allowFullScreen="0" 
            allow="autoplay">
        </iframe>
      </div>
    </>
  )
}

export default IndexPage
